<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Products',

    metaInfo: {
      title: 'NanotechSolutions Sdn Bhd',
      titleTemplate: 'NanotechSolutions Sdn Bhd Products',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keyword', content: 'Nanotech, NanotechSolutions, NanotechSolutions Sdn Bhd, Chemical, Chemical Distributor, chemical supplier, Malaysia, Specialty Chemical, Chemical Industries, Rubber Industries, Raw Material, Petrochemical, Coating, Paper, Personal care, Food, Activated carbon, coconut shell, oleochemical, antioxidant, antitack powder free, antifoam, acrylic polymer binder, biocides, coagulant, dispersing agent, fragrance, flavour, formic acid, nitrile butadiente latex, NBL, synthetic latex supplier, latex, acrylonitrile butadiene latex, PVC, PVC Resin paste, Polyurethane Wet Donning, Polyurethane polymer, Titanium dioxide, Wetting agents, Wetting donning agents, South East Asia, formulations, chemical logistics, international distribution, chemical solution provider, connected globally, industry standard chemical products, quality raw materials' },
        { name: 'description', content: 'Nanotech Solutions is based in Malaysia specialising in specialty chemicals for a diverse number of industries including rubber, glove, caoting, paper, food, personal care and many others.' },
      ],
    },

    extends: View,

    mixins: [
      LoadSections([
        'products-main',
        'gallery',
        'product-technology',
        'banner',
        'info-nanotech',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'Products',
      },
    },
  }
</script>
